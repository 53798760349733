import React from 'react'
import Layout from '../layout'
import SideMenu from '../sideMenu/side-menu'
import { TranslatedLink } from '../TranslatedLink'
import { AnchorClickToNavigateWrapper, getTranslateFunction } from '../../utilities'
/* import ContactForm from '../contactForm/ContactForm' */
import PageTitle from '../pageTitle'
import snarkdown from 'snarkdown'
import Reveal from '../reveal'
import './Pages.scss';
import HoverBtn from '../HoverBtn'
import ContactUs from '../contactUs/ContactUs'

const uxPrincipeTemplate = ({ data, lang }) => {
  const uxPrincipe = data.uxPrincipe.childMarkdownRemark.frontmatter

  const t = getTranslateFunction(lang)

  return (
    <Layout lang={lang} className={'mobile-menu-offset HowWeWork ux-principle'}>
      <AnchorClickToNavigateWrapper>
        <section className="about-us-top margin-top">
          <div className="container container-to-xxl-off">
            <div className="row subpage-title align-items-center fix-nav-offset">
              <div className="col-lg-8 col-xxl-11 push-lg-3 push-xxl-none">
                <PageTitle title={uxPrincipe.title} description={uxPrincipe.description} />
                <p>{uxPrincipe.subtitle}</p>
              </div>
              <div className="col-lg-8 col-xxl-11 push-lg-3 push-xxl-none">
                <p className="description-smaller pr-0" dangerouslySetInnerHTML={{ __html: snarkdown(uxPrincipe.description) }} />
              </div>
            </div>
          </div>
        </section>

        <Reveal>
          <section className="references">
            <div className="d-md-flex">
              <div className="d-none d-lg-block col-md-3 side-menu-height-reference" id="left">
                <SideMenu lang={lang} isGhost={false} />
              </div>
              <div className="col-md-10 col-xl-5 push-md-1 mr-auto">
                <div className="row">
                  {uxPrincipe.steps.map((step, i) => {
                    const dir = (i % 2)
                    const directionClass = dir ? 'item-right' : 'item-left'
                    return (
                      <Reveal key={i} otherClassName={'work-item ' + directionClass + (i ? '' : ' first-work-item')}>
                        <div className={'text'}>
                          
                            <h3 className='d-flex align-items-center mb-4 mb-md-0'>
                                <span className="number mr-3 mb-0 d-md-none">{i + 1}</span>
                                {step.title}
                            </h3>
                          <p dangerouslySetInnerHTML={{ __html: snarkdown(step.text) }} />
                        </div>
                        <div className={'pic d-none d-md-block'}>
                          {/*<img src={step.icon.publicURL} alt={step.title}/>*/}
                          {/*It use SVGs so gatsby-image is not necessary here*/}
                          <span className="number">{i + 1}</span>
                        </div>
                      </Reveal>
                    )
                  })}
                </div>
                <div className='text-center ux-button-fix'>
                  <HoverBtn>
                    <TranslatedLink to="/ux-ui-dizajn">Zistite viac o UX</TranslatedLink>
                  </HoverBtn>
                </div>
              </div>
            </div>
          </section>
        </Reveal>

        <Reveal>
          <ContactUs
            blockClassName={'services'}
            LeftSideTitle={t('References.MoreProjects.Title')}
            LeftSideSubTitle={'Kto sme'}
            LeftSideLink={'/o-nas'}
            linkRight="/en/contact"
            rightSecondLink="/en/brief"
          />
        </Reveal>

       {/*  <ContactForm /> */}
      </AnchorClickToNavigateWrapper></Layout>)
}

export default uxPrincipeTemplate
