import React from 'react'
import UxPrincipeTemplate from '../../components/pages/uxPrincipeTemplate'
import { graphql } from 'gatsby'

const UxPrincipe = ({ data }) => (<UxPrincipeTemplate data={data} lang={'sk'}/>)

export const query = graphql`{
    uxPrincipe: file(relativePath: {eq: "pages/sk/about-us/ux_principe.md"}) {
        childMarkdownRemark {
            frontmatter {
                title
                subtitle
                description
                steps {
                    title
                    text              
                }
            }
        }
    }
}
`

export default UxPrincipe

